import React, { FC, Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'gatsby'
import { Logo } from './logo'

interface HeaderProps {}
export const Header: FC<HeaderProps> = () => {
  return (
    <header className="sticky top-0 z-50 bg-white">
      <Popover>
        <div className="flex items-center justify-between h-[var(--header-height)] mx-auto container px-4 ">
          <Link to="/">
            <Logo className="h-[36px]" />
          </Link>
          <div className="flex items-center -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-ebony/50 bg-gray-50 hover:text-ebony hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ebony/30">
              <span className="sr-only">Open main menu</span>
              <MenuIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <nav className="items-center hidden space-x-16 md:flex">
            {/* <Link to="/explore" className="text-sm font-medium text-gray-600 hover:text-gray-900">
              Explore
            </Link> */}
            {/* <Link to="https://blog.inszu.com" className="text-sm font-medium text-gray-600 hover:text-gray-900">
              Blog
            </Link> */}
            <Link to="/contact" className="text-sm font-medium text-gray-600 hover:text-gray-900">
              contact us
            </Link>
          </nav>
        </div>
        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-10 p-2 transition origin-top-right transform md:hidden"
          >
            <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <Logo className="h-[36px]" />
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ebony/30">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <nav className="flex flex-col px-4 py-4 mt-4 space-y-2 border-t border-t-slate-200">
                {/* <Link
                  to="/explore"
                  className="p-2 font-medium text-gray-600 rounded hover:text-gray-900 hover:bg-slate-100"
                >
                  Explore
                </Link> */}
                <Link
                  to="https://blog.inszu.com"
                  className="p-2 font-medium text-gray-600 rounded hover:text-gray-900 hover:bg-slate-100"
                >
                  Blog
                </Link>
                <Link
                  to="/contact"
                  className="p-2 font-medium text-gray-600 rounded hover:text-gray-900 hover:bg-slate-100"
                >
                  contact us
                </Link>
              </nav>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  )
}
